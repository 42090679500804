import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';

export const EmailFormField = ({ label, value, name, onChange, error, testId }) => (
  <FormControl
    sx={{ marginBottom: 2 }}
    error={error != undefined}
  >
    <FormLabel>{label}</FormLabel>
    <Input
      sx={{ width: { xs: '100%', md: 320 } }}
      value={value}
      onChange={onChange}
      placeholder={'name@organisation.com'}
      name={name}
      slotProps={{
        input: { 'data-component': testId }
      }}
    />
    <FormHelperText
      aria-live={'polite'}
      data-component={'email-error'}
    >
      {error}
    </FormHelperText>
  </FormControl>
);
