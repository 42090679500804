import { Button, Stack, Typography } from '@mui/joy';
import { useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { getAPI } from 'lib/APIHelpers';
import { useLocale, useResetKiosk } from 'hooks';

import { PageHeader } from 'elements/PageHeader';
import { WYSIWYGRenderer } from 'elements/WYSIWYGRenderer/WYSIWYGRenderer';
import { LogoCentered } from 'elements/logo/LogoCentered';
import { MainWrapper } from 'elements/MainWrapper';
import { ThankYouLoadingSkeleton } from './ThankYouLoadingSkeleton';
import { SendResponses } from './send-responses/SendResponses';

export const ThankYou = () => {
  const [locale] = useLocale();

  const { t } = useTranslation();

  const {
    survey_uuid,
    account_organisation_name,
    image,
    enable_response_export,
    is_kiosk_mode
  } = useOutletContext();

  const { feedbackID } = useParams();

  const { isPending, data, isFetching } = useQuery({
    queryKey: ['getWelcomeData', feedbackID],
    queryFn: () => getAPI(`api/v2/feedback/${feedbackID}/completion`),
    throwOnError: true,
  });

  const { onResetKiosk } = useResetKiosk({ feedbackID, survey_uuid });

  const {
    self_reporter,
    text_templates: {
      completion: {
        body
      } = {},
      completion_self_assessment: {
        body: body_self_assessment
      } = {}
    } = {},
  } = data || {};

  if (isFetching || isPending) return <ThankYouLoadingSkeleton />;

  const thankYouPageTemplate =
    self_reporter ? body_self_assessment : body;

  const template = thankYouPageTemplate[locale];

  return (
    <>
      <PageHeader
        hideLogo
        hideBackToWelcomePage
        hideSaveOrExit
      />
      <MainWrapper style={{ paddingTop: 40 }}>
        <LogoCentered
          image={image}
          account_organisation_name={account_organisation_name}
        />
        <Stack
          component={'section'}
          alignItems={'center'}
          spacing={5}
        >
          <WYSIWYGRenderer template={template} />
          {(enable_response_export && !is_kiosk_mode) && (
            <SendResponses
              feedbackID={feedbackID}
            />
          )}
          {is_kiosk_mode && (
            <>
              {!enable_response_export && (
                <Typography level={'h2'} fontSize={24}>
                  {t('Select exit to end this session.')}
                </Typography>
              )}
              <Button
                size={'lg'}
                color={enable_response_export ? 'neutral' : 'primary'}
                variant={enable_response_export ? 'outlined' : 'solid'}
                onClick={onResetKiosk}
              >
                {t('Exit')}
              </Button>
            </>
          )}
        </Stack>
      </MainWrapper>
    </>
  );
};

export default ThankYou;
